import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap home-mission-section">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Unique Mission <br /> {" "}
                                
                            </h2>
                            <p>Our aim is to provide business solutions to all of our clients globally and help them in - achieving maximum output in the daily work shift and having an overall control and view of the activities taking place at the location with a reach out to people and places globally.</p>

                            <p>Our vision is to be a "solution provider" for every business challenges that a business faces in its operation.</p>

                            <div className="read-more-btn mt--50">
                                <Link className="btn-default btn-icon" to="/about-us">Learn More <i className="icon"><FiArrowRight /></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionOne customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
