import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import TechStackTag from '../../components/techStack/TechStackTag';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import ServiceInner from '../service/ServiceInner';
import SlipThree from "../../elements/split/SlipThree";

const ServiceList = [
    {
        title: 'Online Store Development',
        description: 'Create a seamless shopping experience for your customers with our Online Store Development services. Our expert team combines cutting-edge technologies, intuitive design, and secure Ecommerce platforms to deliver user-friendly interfaces and immersive shopping experiences. With responsive web design, intuitive navigation, and seamless integrations, we empower businesses to drive conversions, increase customer satisfaction, and achieve online success.',
    },
    {
        title: 'B2B Ecommerce Solutions',
        description: 'Streamline your business-to-business transactions with our customized B2B Ecommerce solutions. Our expert team combines robust technologies, personalized pricing, and seamless integrations with ERP systems to enhance efficiency and foster strong B2B relationships. With features like bulk ordering, RFQ management, and customized workflows, we empower businesses to drive growth, streamline procurement processes, and deliver exceptional B2B experiences.',
    },
    {
        title: 'Inventory & Warehouse Management',
        description: 'Optimize your operations and maximize efficiency with our Inventory & Warehouse Management solutions. Our expert team leverages advanced technologies to provide real-time inventory tracking, streamlined order fulfillment, and effective inventory control. With seamless integration with popular Ecommerce platforms like Shopify, WooCommerce, or Magento, we empower businesses to enhance operational efficiency, reduce costs, and deliver a seamless customer experience.',
    },
    {
        title: 'Multi-Vendor Marketplace',
        description: 'Empower multiple sellers on a centralized platform with our Multi-Vendor Marketplace solutions. We leverage cutting-edge technologies, intuitive interfaces, and secure payment integration to create vibrant online marketplaces where sellers can thrive and customers can enjoy a diverse selection of products. With comprehensive seller dashboards, robust order management, rating and review systems, and secure payment distribution, we foster collaboration and create a seamless online shopping experience.',
    },
]

const ServiceContent = {
    description : "<p>Elevate your online business with our comprehensive Ecommerce solutions. We combine our expertise in user-centric design, cutting-edge technologies, and industry best practices to create engaging and conversion-focused online stores. From strategic planning and intuitive navigation to seamless checkout experiences and personalized product recommendations, we optimize every aspect of your Ecommerce website. Our team of skilled developers ensures seamless integration with secure payment gateways, enabling smooth transactions and building trust with your customers. With our inventory management solutions, you can efficiently track stock levels, manage orders, and streamline your supply chain. Power your online business with our end-to-end Ecommerce solutions and establish a strong digital presence that drives sales and growth.</p>",
    imagesrc : "/images/service/illustrations/ecommerce.svg",
    imagealt : "Ecommerce Solutions",
}

const Ecommerce = () => {
    return(
        <>
            <SEO 
            title="Comprehensive Ecommerce Solutions | Jubilant Business Solutions" 
            mdescription="Discover robust ecommerce solutions at Jubilant Business Solutions. Our team specializes in creating and optimizing online stores that deliver seamless shopping experiences. From design to payment integration, we help businesses succeed in the digital marketplace."
            keywords="Ecommerce solutions, Online store development, Ecommerce expertise, Seamless shopping experiences, Ecommerce design, Payment integration, Digital marketplace success, Jubilant Business Solutions ecommerce, Ecommerce optimization, Online business growth."
            />
            <Layout>

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                {/* <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "E-commerce"
                                    title = "We are a Ecommerce Development Company."
                                    description = ""
                                /> */}
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">E-commerce</span></h3>
                                    </div>
                                    <h1 className="title">We are a Ecommerce Development Company.</h1>
                                </div>
                            </div>
                        </div>
                        <SlipThree 
                            serviceContent = {ServiceContent}
                        />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "E-Commerce"
                                    title = "Our Ecommerce Solutions"
                                    description = ""
                                />
                            </div>
                        </div>
                        <ServiceInner 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-left"
                            serviceLayout = "col-lg-6 col-md-6 col-sm-12 col-12"
                            serviceList = {ServiceList}
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Tech Stack"
                                    title = "Technology Stack"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="category-meta">
                                <TechStackTag />
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout>

            

                              



        </>
    );
}

export default Ecommerce;