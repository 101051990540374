import PropTypes from "prop-types";
import React from 'react';
import { Helmet } from "react-helmet";


const SEO = ( {title, mdescription, keywords} ) => {
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="robots" content="index, follow" />
                <meta name="description" content={mdescription} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={mdescription} />
                <meta property="og:image" content="https://jubilantbusinesssolutions.com/jubilant-og.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={mdescription} />
                <meta name="twitter:image" content="https://jubilantbusinesssolutions.com/jubilant-og.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="keywords" content={keywords} />
            </Helmet>
            
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string,
    mdescription: PropTypes.string,
    keywords: PropTypes.string
};

export default SEO;