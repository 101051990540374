import React from 'react';
// import CountUp from 'react-countup';
// import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

// const Data = [
//     {
//         countNum : 199,
//         countTitle: 'Happy Clients.',
//     },
//     {
//         countNum : 575,
//         countTitle: 'Employees',
//     },
//     {
//         countNum : 69,
//         countTitle: 'Useful Programs',
//     },
//     {
//         countNum : 500,
//         countTitle: 'Useful Programs',
//     },
// ];

const SlipThree = ({serviceContent}) => {
    // console.log(serviceContent)
    const val = serviceContent;
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                
                <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src={val.imagesrc} alt={val.imagealt} />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            {/* <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="title" dangerouslySetInnerHTML={{__html: val.title}}></h4>
                            </ScrollAnimation> */}
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </ScrollAnimation>

                            {/* <div className="row">
                                {Data.map((data, index) => (
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="count-box counter-style-4 text-start">
                                            <TrackVisibility once>
                                                {({ isVisible }) => isVisible && 
                                                    <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                                            </TrackVisibility>
                                            <h5 className="counter-title">{data.countTitle}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    )
}
export default SlipThree;