import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contact from "./elements/contact/Contact";


//Service Pages
import WebDesignAndDevelopment from './elements/serviceDetail/WebDesignAndDevelopment';
import SoftwareDevelopment from './elements/serviceDetail/SoftwareDevelopment';
import MobileAppDevelopment from './elements/serviceDetail/MobileAppDevelopment';
import CloudAndDevops from './elements/serviceDetail/CloudAndDevops';
import Ecommerce from './elements/serviceDetail/ecommerce';
import DigitalMarketing from './elements/serviceDetail/DigitalMarketing';
import UIUXDesign from './elements/serviceDetail/UIUXDesign';
import SoftwareMaintenance from './elements/serviceDetail/SoftwareMaintenance';

// Import Css Here 
import './assets/scss/style.scss';
import Error from './pages/Error';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>


                    {/* Service Pages  */}
                    <Route path={`${process.env.PUBLIC_URL + "/services/web-design-and-development"}`} exact component={WebDesignAndDevelopment}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services/software-development"}`} exact component={SoftwareDevelopment}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services/mobile-app-development"}`} exact component={MobileAppDevelopment}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services/cloud-and-devops"}`} exact component={CloudAndDevops}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services/ecommerce"}`} exact component={Ecommerce}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services/digital-marketing"}`} exact component={DigitalMarketing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services/ui-ux-design"}`} exact component={UIUXDesign}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services/software-maintenance"}`} exact component={SoftwareMaintenance}/>

                    <Route path="*" component={Error} />
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
