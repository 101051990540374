import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
// import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";

const Contact = () => {
    return (
        <>
            <SEO 
            title="Contact | Jubilant Business Solutions" 
            mdescription="Contact Jubilant Business Solutions to discuss your software development and digital solutions needs. We're here to answer your questions and explore how we can collaborate on your next project. Reach out to our expert team today."
            keywords="Contact Jubilant Business Solutions, Reach out, Contact information, Collaborate on projects, Software development inquiries, Digital solutions discussion, Get in touch, Expert team, Project collaboration."
            />
            <Layout>
                {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact"
                /> */}
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    {/* <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Get in Touch with Us"
                                        title = "Reach Out and Connect"
                                        description = ""
                                    /> */}
                                    <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Get in Touch with Us</span></h3>
                                    </div>
                                    <h1 className="title">Reach Out and Connect</h1>
                                </div>
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;