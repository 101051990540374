import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import TechStackTag from '../../components/techStack/TechStackTag';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import SlipThree from "../../elements/split/SlipThree";

import ServiceInner from '../service/ServiceInner';

const ServiceList = [
    {
        // icon: '/images/service/icons/web-development.svg',
        title: 'Front-end Development',
        description: 'Our front-end development experts specialize in leveraging cutting-edge technologies such as Angular, React, and Vue to create visually stunning and interactive user interfaces (UIs). By harnessing the power of these frameworks, we build reusable components that enhance user experiences and streamline development. With a strong focus on responsive web design, dynamic UI elements, and performance optimization techniques, we ensure seamless user experiences across devices.',
        // url : '/web-design-and-development'
    },
    {
        // icon: '/images/service/icons/software-development.svg',
        title: 'Back-end Development',
        description: 'Our skilled back-end development team utilizes technologies like Python, Ruby, PHP, and Node.js to build robust server-side components for your web application. We specialize in efficient database management using SQL and NoSQL technologies, develop secure and scalable APIs for seamless integration, and prioritize data security with authentication and encryption measures. Trust us to create a powerful foundation that empowers your web application\'s performance, scalability, and security.',
        // url : '/'
    },
    {
        // icon: '/images/service/icons/mobile-app.svg',
        title: 'Full-stack Development',
        description: 'At Jubilant Business Solutions, our full-stack development team provides comprehensive end-to-end solutions for web applications. Combining front-end expertise in HTML, CSS, and JavaScript with back-end proficiency in Python, Ruby, PHP, and Node.js, we seamlessly integrate technologies to create cohesive and scalable applications. From UI design to server-side implementation, experience the power of our full-stack approach in delivering complete solutions tailored to your business needs.',
        // url : '/'
    },
    {
        // icon: '/images/service/icons/devops.svg',
        title: 'Database Management',
        description: 'In today\'s data-driven world, efficient database management is crucial for web application development. At our organization, our expert database management team ensures efficient data storage, retrieval, and security for your web application. We design optimized database schemas, fine-tune queries for performance, and implement robust security measures. With a focus on data integrity, privacy, and compliance, our solutions guarantee reliable database performance and safeguard your valuable information.',
        // url : '/'
    },
]

const ServiceContent = {
    description : "<p>We specialize in creating visually stunning and highly functional websites that leave a lasting impression on your audience. Our Web Design and Development service combines innovative design concepts with cutting-edge technologies to deliver a seamless user experience. Whether you need a simple brochure website or a complex e-commerce platform, our expert team is dedicated to bringing your vision to life. With custom web design, responsive development, content management systems, and e-commerce expertise, we ensure that your website stands out in the digital landscape and engages your target audience effectively.</p>",
    imagesrc : "/images/service/illustrations/web-design.svg",
    imagealt : "Web design and development",
}

    

const WebDesignAndDevelopment = () => {
    return(
        <>
            <SEO 
            title="Web Design and Development Company | Jubilant Business Solutions"
            mdescription="Experience top-notch web design and development services at Jubilant Business Solutions. Our skilled team combines creativity and technical expertise to deliver stunning and user-friendly websites. From responsive design to seamless functionality, we create online experiences that captivate and convert."
            keywords="Web design and development, Professional website design, Responsive web development, User-friendly websites, Creative web solutions, Expert web developers, Captivating online experiences, Website functionality, Jubilant Business Solutions services, Engaging web design, Creative UI/UX, Effective online presence." 
            />
            <Layout>

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                {/* <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Web Design & Development"
                                    title = "We are a Web App Development Company."
                                    description = ""
                                /> */}
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Web Design & Development</span></h3>
                                    </div>
                                    <h1 className="title">We are a Web App Development Company.</h1>
                                </div>
                                
                            </div>
                        </div>
                        <SlipThree 
                            serviceContent = {ServiceContent}
                        />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Web Design & Development"
                                    title = "Our Web Development Services"
                                    description = ""
                                />
                            </div>
                        </div>
                        <ServiceInner 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-left"
                            serviceLayout = "col-lg-6 col-md-6 col-sm-12 col-12"
                            serviceList = {ServiceList}
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Tech Stack"
                                    title = "Technology Stack"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="category-meta">
                                <TechStackTag />
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout>


        </>
    );
}

export default WebDesignAndDevelopment;