import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import TechStackTag from '../../components/techStack/TechStackTag';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import ServiceInner from '../service/ServiceInner';
import SlipThree from "../../elements/split/SlipThree";

const ServiceList = [
    {
        title: 'Search Engine Optimization (SEO)',
        description: 'Boost your online visibility and drive organic traffic with our specialized Search Engine Optimization (SEO) services. Our expert team utilizes industry best practices, advanced tools, and data-driven insights to optimize your website, improve search engine rankings, and increase organic traffic. From comprehensive website analysis and strategic keyword optimization to technical SEO enhancements and content optimization, we help businesses achieve higher search visibility and reach their target audience effectively.',
    },
    {
        title: 'Pay-Per-Click Advertising (PPC)',
        description: 'Drive targeted traffic and maximize your advertising ROI with our specialized Pay-Per-Click (PPC) advertising services. Our expert team develops customized strategies, crafts compelling ad copy, and optimizes keyword management to deliver highly targeted campaigns across platforms like Google Ads, Bing Ads, and social media channels. Accelerate your online visibility, increase conversions, and optimize your ad spend with our results-driven PPC solutions.',
    },
    {
        title: 'Social Media Marketing',
        description: 'Engage your audience and amplify your brand with our expert Social Media Marketing services. We develop tailored strategies, create compelling content, and actively manage your social media community to foster engagement and build brand awareness. By leveraging popular platforms like Facebook, Instagram, Twitter, and LinkedIn, we help businesses strengthen their online presence, drive meaningful connections, and achieve their marketing objectives.',
    },
    {
        title: 'Content Marketing',
        description: 'Capture your audience\'s attention and drive conversions with our specialized Content Marketing services. We develop tailored strategies, create informative and engaging blog posts, articles, and visual content, and optimize and promote your content to reach your target audience effectively. By combining strategic planning, creativity, and technical expertise, we help businesses attract, engage, and convert their audience through valuable and compelling content.',
    },
]

const ServiceContent = {
    description : "<p>Drive online success and grow your business with our tailored Digital Marketing services. We combine data-driven strategies, creative expertise, and industry insights to help you achieve your online goals. Our team of digital marketing specialists works closely with you to develop customized campaigns that drive targeted traffic to your website, enhance your brand visibility, and increase your online reach. From comprehensive SEO strategies that improve your organic search rankings to effective PPC advertising campaigns that maximize your ROI, we utilize the latest tools and techniques to optimize your online presence. Our social media marketing services help you engage with your audience, build brand loyalty, and drive meaningful interactions.</p>",
    imagesrc : "/images/service/illustrations/digital-marketing.svg",
    imagealt : "Ecommerce Solutions",
}

const DigitalMarketing = () => {
    return(
        <>
            <SEO 
            title="Strategic Digital Marketing Services | Jubilant Business Solutions" 
            mdescription="Elevate your online presence with strategic digital marketing services from Jubilant Business Solutions. Our experienced team crafts data-driven campaigns that increase visibility, engagement, and conversions. Partner with us to harness the power of digital marketing for business growth."
            keywords="Digital marketing services, Strategic online marketing, Expert digital marketers, Data-driven campaigns, Online presence boost, Conversion-focused marketing, Business growth, Jubilant Business Solutions digital marketing, Online visibility, Engaging campaigns."
            />
            <Layout>

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                {/* <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Digital Marketing"
                                    title = "We are a Digital Marketing Company."
                                    description = ""
                                /> */}
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Digital Marketing</span></h3>
                                    </div>
                                    <h1 className="title">We are a Digital Marketing Company.</h1>
                                </div>
                            </div>
                        </div>
                        <SlipThree 
                            serviceContent = {ServiceContent}
                        />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Digital Marketing"
                                    title = "Our Digital Marketing Solutions"
                                    description = ""
                                />
                            </div>
                        </div>
                        <ServiceInner 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-left"
                            serviceLayout = "col-lg-6 col-md-6 col-sm-12 col-12"
                            serviceList = {ServiceList}
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Tech Stack"
                                    title = "Technology Stack"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="category-meta">
                                <TechStackTag />
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout>

            

                              



        </>
    );
}

export default DigitalMarketing;