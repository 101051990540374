import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import TechStackTag from '../../components/techStack/TechStackTag';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import ServiceInner from '../service/ServiceInner';
import SlipThree from "../../elements/split/SlipThree";

const ServiceList = [
    {
        title: 'Cloud Infrastructure Management',
        description: 'Optimize your cloud infrastructure and achieve secure and scalable software deployments with our Cloud Infrastructure Management services. Our experienced team specializes in managing leading cloud platforms like AWS, Azure, and Google Cloud to ensure secure and scalable deployments. We configure and optimize infrastructure components, implement robust monitoring and security practices, and enable seamless scalability to meet your software application\'s demands.',
    },
    {
        title: 'DevOps Automation',
        description: 'Streamline your software development lifecycle with our DevOps Automation services. Our experienced team embraces DevOps principles & utilizes industry-standard tools like Jenkins, GitLab CI/CD, & Docker to automate build, test, & deployment workflows. By implementing CI/CD pipelines, automating build & test processes, & leveraging infrastructure-as-code & containerization technologies, we enhance collaboration, efficiency, & quality in your software development processes.',
    },
    {
        title: 'Cloud Monitoring and Security',
        description: 'Ensure the reliability and security of your cloud deployments with our Cloud Monitoring and Security services. Our team leverages advanced monitoring tools like Amazon CloudWatch, Azure Monitor, and Google Cloud Monitoring to proactively monitor the performance and availability of your cloud infrastructure. With incident response capabilities, compliance audits, and robust access controls, we safeguard your applications and ensure a secure and highly available cloud environment.',
    },
    {
        title: 'Scalability and Elasticity',
        description: 'Achieve seamless scalability and elasticity for your applications with our Scalability and Elasticity services. Our team leverages cloud resources, auto-scaling capabilities, and infrastructure-as-code practices to ensure your applications can handle increased demand, workload spikes, and evolving business needs. With auto-scaling mechanisms, infrastructure-as-code, load balancing, and performance optimization, we empower your applications to grow and perform with confidence in the cloud.',
    },
]

const ServiceContent = {
    description : "<p>Maximize the potential of cloud computing and DevOps practices with our Cloud & DevOps services. From cloud infrastructure management and DevOps automation to continuous delivery, scalability, and cost optimization, we empower your software development efforts with efficiency, agility, scalability, and accelerated time-to-market. With our expertise in cloud platforms, containerization, automation tools, and DevOps methodologies, we optimize your software development lifecycle, streamline operations, and enable seamless deployment and management of your applications.</p>",
    imagesrc : "/images/service/illustrations/devops.svg",
    imagealt : "Cloud & DevOps Solutions",
}

const CloudAndDevops = () => {
    return(
        <>
            <SEO 
            title="Efficient Cloud and DevOps Solutions | Jubilant Business Solutions"
            mdescription="Unlock the power of efficient cloud and DevOps solutions at Jubilant Business Solutions. Our expert team helps businesses streamline operations, enhance scalability, and accelerate development cycles through cloud adoption and DevOps practices. Discover how our services can drive your digital transformation." 
            keywords="Cloud and DevOps solutions, Streamlined operations, Scalable infrastructure, DevOps practices, Business growth, Efficient cloud services, Expert DevOps team, Digital transformation, Cloud adoption, Jubilant Business Solutions services."
            />
            <Layout>

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                {/* <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Cloud & DevOps"
                                    title = "We are a Cloud & DevOps Solutions Company."
                                    description = ""
                                /> */}
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Cloud & DevOps</span></h3>
                                    </div>
                                    <h1 className="title">We are a Cloud & DevOps Solutions Company.</h1>
                                </div>
                            </div>
                        </div>
                        <SlipThree 
                            serviceContent = {ServiceContent}
                        />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Cloud & DevOps"
                                    title = "Our Cloud & DevOps Solutions"
                                    description = ""
                                />
                            </div>
                        </div>
                        <ServiceInner 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-left"
                            serviceLayout = "col-lg-6 col-md-6 col-sm-12 col-12"
                            serviceList = {ServiceList}
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Tech Stack"
                                    title = "Technology Stack"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="category-meta">
                                <TechStackTag />
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout>

            

                              



        </>
    );
}

export default CloudAndDevops;