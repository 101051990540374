import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


const ServiceInner = ({textAlign, serviceStyle, serviceList, serviceLayout}) => {
    return (
        <div className="row row--15 service-wrapper">
              {serviceList.map( (val , i) => (
                <div className={`${serviceLayout}`} key={i}>
                    

                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="content">
                                <h4 className="title" dangerouslySetInnerHTML={{__html: val.title}}></h4>
                                <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceInner;