import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";

const AboutUs = () => {
    return (
        <>
            <SEO 
            title="About Us | Jubilant Business Solutions - Empowering Digital Transformation in India"
            mdescription="Get to know Jubilant Business Solutions, a pioneering software development company based in India. With a dedicated team of experts, we are committed to driving digital transformation through our services in web design, software development, mobile app development, cloud solutions, e-commerce, digital marketing, UI/UX designing, and software maintenance."
            keywords="Software development company, About Jubilant Business Solutions, Our journey, Expertise, Digital transformation, Web design, Software development, Mobile app development, Cloud solutions, E-commerce solutions, Digital marketing, UI/UX designing, Software maintenance, Team of experts, India-based company." 
            />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-450 bg_image" data-black-overlay="2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/about/about-b-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">About Us</span></h3>
                                    </div>
                                    <h1 className="title display-one-about-banner">We are a Web & App <br /> Development Company.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            
                            <div className="col-lg-12">
                                <p className="mb--10">Welcome to Jubilant Business Solutions! We are a dynamic team of unique individuals with diverse backgrounds, united by a common mission and shared vision. By merging multiple concepts and harnessing our collective expertise, we have created a thriving company that delivers exceptional business solutions.</p>

                                <p className="mb--10">Based in the vibrant IT Hub of Telangana - Hyderabad, our journey began with a group of qualified Engineers representing various verticals. Our goal? To provide comprehensive business solutions to both existing and startup organizations, empowering them to thrive in today's competitive landscape.</p>

                                <p className="mb--10">At Jubilant Business Solutions, we pride ourselves on being a team of dedicated engineers who possess a deep understanding of business requirements. We take the time to analyze and comprehend the specific needs of our clients, ensuring that we develop tailored solutions that align with their objectives. By doing so, we help our clients overcome challenges they face in their day-to-day operations, allowing them to achieve their goals and reach new heights of success.</p>

                                <p className="mb--10">What sets us apart is our unwavering commitment to delivering the best solutions while upholding strong business ethics. We believe that the success of our clients is our success, and therefore, we go above and beyond to ensure their utmost satisfaction. We take pride in building long-lasting relationships with our clients, earning their trust through our consistent delivery of high-quality services.</p>

                                <p className="mb--10">Our prime motto is to continuously strive for excellence in everything we do. We are fueled by the passion to provide top-notch solutions, contribute to the growth of our clients, and positively impact their businesses. By staying at the forefront of technological advancements and embracing innovation, we ensure that our clients benefit from the most cutting-edge solutions available.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Our Services"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rwt-timeline-area rn-section-gap rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "How we work for our clients"
                                    title = "Our Process"
                                    description = ""
                                    />
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default AboutUs;
