import React from 'react';
import {Link} from "react-router-dom";
import CalltoActionTwo from "../../elements/calltoaction/CalltoActionTwo";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiInstagram, FiLinkedin, FiPhone, FiMail, FiYoutube } from "react-icons/fi";


const footerData = [
    {
        "id": 1,
        "title": ""
    },

    {
        "id": 2,
        "title": "Quick Links",
        "quicklink": [
            {
                "id": 1,
                "text": "About Us",
                "url": "/about-us"
            },
            {
                "id": 2,
                "text": "Portfolio",
                "url": "/"
            },
            {
                "id": 3,
                "text": "Clients",
                "url": "/"
            }
            
        ]
    },

    {
        "id": 3,
        "title": "Services",
        "quicklink": [
            {
                "id": 1,
                "text": "Web Design &  Development",
                "url": "/services/web-design-and-development"
            },
            {
                "id": 2,
                "text": "Software Development",
                "url": "/services/software-development"
            },
            {
                "id": 3,
                "text": "Mobile App Development",
                "url": "/services/mobile-app-development"
            },
            {
                "id": 4,
                "text": "Cloud & DevOps",
                "url": "/services/cloud-and-devops"
            }
        ]
    },

    {
        "id": 4,
        "title": "Services",
        "quicklink": [
            {
                "id": 1,
                "text": "UI UX Design",
                "url": "/services/ui-ux-design"
            },
            {
                "id": 2,
                "text": "E Commerce",
                "url": "/services/ecommerce"
            },
            {
                "id": 3,
                "text": "Digital Marketing",
                "url": "/services/digital-marketing"
            },
            {
                "id": 4,
                "text": "Software Maintenance",
                "url": "/services/software-maintenance"
            }
        ]
    },

    {
        "id": 5,
        "title": "Stay With Us.",
        "subtitle": "2000+ Our clients are subscribe Around the World"
    }
]

const footerIntemOne =  footerData[1];
const footerIntemTwo =  footerData[2];
const footerIntemThree =  footerData[3];
const footerIntemFive =  footerData[4];

const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);

const FooterTwo = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <CalltoActionTwo />
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemOne.title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {indexOneLink.map((data, index) => (
                                                <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">{footerIntemTwo.title}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {indexThreeLink.map((data, index) => (
                                                    <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemThree.title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {indexTwoLink.map((data, index) => (
                                                <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemFive.title}</h4>
                                    <div className="inner">
                                        {/* <h6 className="subtitle">{footerIntemFive.subtitle}</h6> */}
                                        <p className="m-0"><FiPhone />  &nbsp; &nbsp;<a href="tel:+919160100365">+91 9160 100 365</a></p>
                                        <p><FiMail />  &nbsp; &nbsp;<a href="mailto:info@jubilantbusinesssolutions.com">info@jubilantbusinesssolutions.com</a></p>
                                        <ul className="social-icon social-default justify-content-start">
                                            <li><a href="https://www.facebook.com/jubilantbusinesssolution" target='_blank' rel="noreferrer"><FiFacebook /></a></li>
                                            {/* <li><Link to="twitter.com"><FiTwitter /></Link></li> */}
                                            <li><a href="https://www.instagram.com/jubilantbusinesssolutions/" target='_blank' rel="noreferrer"><FiInstagram /></a></li>
                                            <li><a href="https://www.linkedin.com/company/jubilantbusinesssolutions/" target='_blank' rel="noreferrer"><FiLinkedin /></a></li>
                                            <li><a href="https://www.youtube.com/@jubilantbusinesssolutions" target='_blank' rel="noreferrer"><FiYoutube /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
