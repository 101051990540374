import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import TechStackTag from '../../components/techStack/TechStackTag';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import ServiceInner from '../service/ServiceInner';
import SlipThree from "../../elements/split/SlipThree";

const ServiceList = [
    {
        title: 'UI/UX Design & Prototyping',
        description: 'Craft captivating mobile experiences while strengthening your brand with our UI/UX Design & Prototyping services. Our expert designers combine a user-centric design approach, industry best practices, and cutting-edge design tools to deliver visually appealing and intuitive interfaces that align with your brand identity. Through interactive prototyping, we bring your app ideas to life, allowing you to visualize and refine the user experience before development.',
    },
    {
        title: 'Native App Development',
        description: 'Elevate your mobile app to new heights with our Native App Development services for iOS and Android. Our team of skilled developers combines their expertise with platform-specific technologies such as Kotlin for Android and Swift for iOS to craft powerful and responsive mobile applications. By harnessing the native capabilities of iOS and Android, we create apps that deliver exceptional performance, seamless user experiences, and seamless integration with device features.',
    },
    {
        title: 'Cross-Platform App Development',
        description: 'Expand your app\'s reach and efficiency with our Cross-Platform App Development services. Our experienced developers utilize frameworks like React Native and Flutter to create high-quality apps that work seamlessly across iOS and Android. By leveraging code reuse and a single codebase, we deliver cost-effective and time-efficient solutions without compromising on user experience. Maximize your app\'s potential, save development time, and reduce costs with our Cross-Platform App Development expertise',
    },
    {
        title: 'App Deployment & Maintenance',
        description: 'Ensure a smooth and successful app experience with our App Deployment & Maintenance services. From handling app store submissions to providing ongoing maintenance and updates, we take care of all aspects of app deployment and ensure your app stays up-to-date and performs flawlessly. With our expertise in app store guidelines, technical support, and performance optimization, we keep your app running smoothly, giving you peace of mind.',
    },
]

const ServiceContent = {
    description : "<p>Empower your business with cutting-edge mobile app development solutions that redefine the way you engage with your customers. From customized app development and native/cross-platform expertise to user-centric design and rigorous testing, we deliver innovative mobile experiences that captivate your audience. Our expert team transforms your ideas into engaging and intuitive mobile experiences.</p>",
    imagesrc : "/images/service/illustrations/mobile-app-development.svg",
    imagealt : "Mobile App development",
}

const MobileAppDevelopment = () => {
    return(
        <>
            <SEO 
            title="Innovative Mobile App Development Services | Jubilant Business Solutions" 
            mdescription="Discover expert mobile app development services at Jubilant Business Solutions. Our skilled team of mobile app developers specializes in creating seamless and user-centric mobile applications. From concept to launch, we turn your app ideas into reality, ensuring an engaging and functional mobile experience."
            keywords="Mobile app development services, Innovative app solutions, Expert mobile app developers, Seamless mobile experiences, User-centric app design, Custom mobile applications, Engaging mobile apps, Transforming app ideas, Jubilant Business Solutions mobile development, App functionality, Native mobile app development, Hybrid mobile app development."
            />
            <Layout>

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                {/* <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Mobile App Development"
                                    title = "We are a Mobile App Company."
                                    description = ""
                                /> */}
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Mobile App Development</span></h3>
                                    </div>
                                    <h1 className="title">We are a Mobile App Company.</h1>
                                </div>
                                
                            </div>
                        </div>
                        <SlipThree 
                            serviceContent = {ServiceContent}
                        />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Mobile App Development"
                                    title = "Our Mobile App Development Services"
                                    description = ""
                                />
                            </div>
                        </div>
                        <ServiceInner 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-left"
                            serviceLayout = "col-lg-6 col-md-6 col-sm-12 col-12"
                            serviceList = {ServiceList}
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Tech Stack"
                                    title = "Technology Stack"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="category-meta">
                                <TechStackTag />
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout>

            

                              



        </>
    );
}

export default MobileAppDevelopment;