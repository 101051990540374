import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import TechStackTag from '../../components/techStack/TechStackTag';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import ServiceInner from '../service/ServiceInner';
import SlipThree from "../../elements/split/SlipThree";

const ServiceList = [
    {
        // icon: '/images/service/icons/web-development.svg',
        title: 'Customized Software Development',
        description: 'We specialize in crafting bespoke solutions that perfectly align with your unique business requirements. Through in-depth analysis and consultations, we define the scope and functionality of your software solution. Leveraging cutting-edge technologies and an Agile development approach, we create intuitive user interfaces and seamless experiences across mobile, web and cloud platforms. Experience the power of customized software built specifically for your business needs.',
        // url : '/web-design-and-development'
    },
    {
        // icon: '/images/service/icons/software-development.svg',
        title: 'Legacy System Migration & Re-engineering',
        description: 'Unlock the full potential of your business by modernizing and enhancing your legacy systems through our legacy system migration and re-engineering services. With meticulous planning, utilization of cutting-edge technologies, and comprehensive re-engineering, we help businesses overcome the limitations of outdated systems and embrace the benefits of modern technology. Future-proof your business and stay ahead of the competition with seamless legacy system migration and re-engineering.',
        // url : '/'
    },
    {
        // icon: '/images/service/icons/mobile-app.svg',
        title: 'Application Integration & ERP Solutions',
        description: 'Streamline your business processes with our Application Integration and ERP Solutions. We specialize in seamlessly integrating applications, systems, and data sources, ensuring data consistency and real-time communication. Our comprehensive ERP solutions encompass core functions like finance, HR, inventory management, and CRM, empowering you with scalable and future-ready solutions. Experience the power of streamlined operations and data-driven decision-making.',
        // url : '/'
    },
    {
        // icon: '/images/service/icons/devops.svg',
        title: 'Maintenance and Support',
        description: 'Ensure the smooth operations and reliable performance of your software applications with our comprehensive Maintenance and Support services. Our dedicated team provides proactive monitoring, timely updates, helpdesk support, and continuous enhancements to maximize the value and longevity of your software investment. From continuous improvements to troubleshooting assistance, we ensure your software operates at its best, allowing you to focus on your core business.',
        // url : '/'
    },
]

const ServiceContent = {
    description : "<p>Unlock the power of custom software development with our team of expert engineers. At Jubilant Business Solutions, we specialize in delivering innovative and tailored solutions that empower businesses to thrive in the digital age. With our extensive experience and commitment to excellence, we transform your ideas into robust, scalable, and high-performing applications. Our dedicated team collaborates closely with you to understand your unique business requirements, ensuring that the software we develop aligns perfectly with your goals and objectives. From conceptualization to deployment, we guide you through every step of the development process, providing proactive communication and transparent project management.</p>",
    imagesrc : "/images/service/illustrations/software-development.svg",
    imagealt : "Software development",
}

const SoftwareDevelopment = () => {
    return(
        <>
            <SEO 
            title="Expert Software Development Services | Jubilant Business Solutions"
            mdescription="We offer top-tier software development services tailored to meet your unique business needs. Our experienced team of developers crafts custom software solutions that drive efficiency, productivity, and growth. Partner with us to bring your software vision to life."
            keywords="Software development services, Custom software solutions, Expert software developers, Innovative software solutions, Business software development, Tailored software applications, Software efficiency, Productivity enhancement, Software growth, Jubilant Business Solutions software development, Cutting-edge technology." 
            />
            <Layout>

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                {/* <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Software Development"
                                    title = "We are a Software Development Company."
                                    description = ""
                                /> */}
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Software Development</span></h3>
                                    </div>
                                    <h1 className="title">We are a Software Development Company.</h1>
                                </div>
                            </div>
                        </div>
                        <SlipThree 
                            serviceContent = {ServiceContent}
                        />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Software Development"
                                    title = "Our Software Development Services"
                                    description = ""
                                />
                            </div>
                        </div>
                        <ServiceInner 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-left"
                            serviceLayout = "col-lg-6 col-md-6 col-sm-12 col-12"
                            serviceList = {ServiceList}
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Tech Stack"
                                    title = "Technology Stack"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="category-meta">
                                <TechStackTag />
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout>

            

                              



        </>
    );
}

export default SoftwareDevelopment;