import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <img src={`${process.env.PUBLIC_URL}/images/service/about-icons/web-development.svg`} alt="Web Development" />,
        title: 'Web Design & Development',
        url : '/services/web-design-and-development'
    },
    {
        icon: <img src={`${process.env.PUBLIC_URL}/images/service/about-icons/software-development.svg`} alt="Software Development" />,
        title: 'Software Development',
        url : '/services/software-development'
    },
    {
        icon: <img src={`${process.env.PUBLIC_URL}/images/service/about-icons/mobile-app.svg`} alt="Mobile Apps" />,
        title: 'Mobile App Development',
        url : '/services/mobile-app-development'
    },
    {
        icon: <img src={`${process.env.PUBLIC_URL}/images/service/about-icons/devops.svg`} alt="Cloud & DevOps" />,
        title: 'Cloud Services and DevOps',
        url : '/services/cloud-and-devops'
    },
    {
        icon: <img src={`${process.env.PUBLIC_URL}/images/service/about-icons/e-commerce.svg`} alt="E-commerce" />,
        title: 'E-commerce Solutions',
        url : '/services/ecommerce'
    },
    {
        icon: <img src={`${process.env.PUBLIC_URL}/images/service/about-icons/digital-marketing.svg`} alt="Digital Marketing" />,
        title: 'Digital <span class="d-none d-xl-inline"><br></span>   Marketing',
        url : '/services/digital-marketing'
    },
    {
        icon: <img src={`${process.env.PUBLIC_URL}/images/service/about-icons/ui-ux-design.svg`} alt="UI UX Design" />,
        title: 'UI UX <span class="d-none d-xl-inline"><br></span> Designing',
        url : '/services/ui-ux-design'
    },
    {
        icon: <img src={`${process.env.PUBLIC_URL}/images/service/about-icons/software-maintenance.svg`} alt="Software Maintenance" />,
        title: 'Software <span class="d-none d-xl-inline"><br></span> Maintenance',
        url : '/services/software-maintenance'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600">
                                    <Link to={`${val.url}`} dangerouslySetInnerHTML={{__html: val.title}}></Link>
                                </h4>
                                {/* <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p> */}
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;