import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import TechStackTag from '../../components/techStack/TechStackTag';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import ServiceInner from '../service/ServiceInner';
import SlipThree from "../../elements/split/SlipThree";

const ServiceList = [
    {
        title: 'Bug Fixing and Issue Resolution',
        description: 'Address software bugs and issues promptly. We conduct thorough diagnostics, swiftly resolves identified issues, and conducts rigorous quality assurance testing to ensure stable and reliable software performance. With our expertise and commitment to delivering optimal results, we aim to keep your software running seamlessly and minimize disruptions. Trust us to handle your software issues effectively, allowing you to focus on your core business objectives.',
    },
    {
        title: 'Feature Enhancements and Upgrades',
        description: 'Keep your software up-to-date and aligned with your evolving business needs with our Feature Enhancements and Upgrades service. Our collaborative approach ensures that we understand your goals and identify opportunities to enhance existing features or develop new ones. With our expertise in cutting-edge technologies, we deliver innovative enhancements and upgrades that improve user experiences, streamline workflows, and keep your software at the forefront of innovation.',
    },
    {
        title: 'Performance Optimization',
        description: 'Achieve optimal software performance with our Performance Optimization service. Our expert team conducts a comprehensive performance audit, optimizes code and infrastructure, and conducts rigorous testing to fine-tune your software for efficiency and responsiveness. By addressing performance bottlenecks and implementing industry best practices, we ensure that your software operates at peak performance, delivering a seamless user experience.',
    },
    {
        title: 'Security Updates and Patch Management',
        description: 'Ensure the security of your software and data with our comprehensive Security Updates and Patch Management service. We proactively identify and address vulnerabilities, provide timely security updates, implement effective patch management strategies, and conduct regular security audits. With our vigilant approach and commitment to protecting your assets, you can have peace of mind knowing that your software remains secure and resilient.',
    },
]

const ServiceContent = {
    description : "<p>Ensure the long-term performance and reliability of your software with our specialized Software Maintenance services. Our dedicated team of experts is equipped with the knowledge and experience to handle all aspects of software maintenance, from promptly addressing bug fixing and resolving issues to implementing feature enhancements that align with your evolving business needs. We also prioritize the security of your software by staying vigilant and providing regular security updates to protect against emerging threats. With our proactive approach and meticulous attention to detail, we ensure that your software remains up-to-date, optimized, and secure, allowing you to focus on your core business operations with confidence. </p>",
    imagesrc : "/images/service/illustrations/software-maintenance.svg",
    imagealt : "Software Maintenance",
}

const SoftwareMaintenance = () => {
    return(
        <>
            <SEO 
            title="Reliable Software Maintenance Services | Jubilant Business Solutions"
            mdescription="Discover reliable software maintenance services at Jubilant Business Solutions. Our skilled team ensures the ongoing performance and security of your software solutions. From updates to bug fixes, we provide comprehensive maintenance to keep your applications running smoothly." 
            keywords="Software maintenance services, Reliable maintenance solutions, Expert software maintenance, Ongoing performance, Software security, Bug fixes, Software updates, Jubilant Business Solutions maintenance, Application reliability, Smooth software performance."
            />
            <Layout>

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                {/* <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Software Maintenance"
                                    title = "We are a Software Maintenance Company."
                                    description = ""
                                /> */}
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Software Maintenance</span></h3>
                                    </div>
                                    <h1 className="title">We are a Software Maintenance Company.</h1>
                                </div>
                            </div>
                        </div>
                        <SlipThree 
                            serviceContent = {ServiceContent}
                        />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Software Maintenance"
                                    title = "Our Software Maintenance Services"
                                    description = ""
                                />
                            </div>
                        </div>
                        <ServiceInner 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-left"
                            serviceLayout = "col-lg-6 col-md-6 col-sm-12 col-12"
                            serviceList = {ServiceList}
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Tech Stack"
                                    title = "Technology Stack"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="category-meta">
                                <TechStackTag />
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout>

            

                              



        </>
    );
}

export default SoftwareMaintenance;