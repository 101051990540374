import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><Link to="/">Home</Link></li>
            <li className="has-droupdown"><Link to="#">Services</Link>
                <ul className="submenu">
                    <li><Link to="/services/web-design-and-development">Web Design Development</Link></li>
                    <li><Link to="/services/software-development">Software Development</Link></li>
                    <li><Link to="/services/mobile-app-development">Mobile App Development</Link></li>
                    <li><Link to="/services/cloud-and-devops">Cloud Services & DevOps</Link></li>
                    <li><Link to="/services/ecommerce">E Commerce Solutions</Link></li>
                    <li><Link to="/services/digital-marketing">Digital Marketing</Link></li>
                    <li><Link to="/services/ui-ux-design">UI UX Design</Link></li>
                    <li><Link to="/services/software-maintenance">Software Maintenance</Link></li>
                </ul>
            </li>
            <li><Link to="/about-us">About Us</Link></li>
            <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
