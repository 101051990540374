import React , {useState} from 'react';
import serverApi from '../../api/server';
import { Controller, useForm } from "react-hook-form";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. We will contact you soon.</p>
    )
}

const ContactForm = ({props , formStyle}) =>  {
    const [ result,showresult ] = useState(false);
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm();

    const sendEmail = async (data) => {
        const headers = { 
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            
        };

        // console.log(data);

        await serverApi.post('/mail.php', data, { headers })
        .then(function (response) {
            // console.log(response);
          })
          .catch(function (error) {
            // console.log(error);
          });

        
        reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={handleSubmit(sendEmail)}>
            <div className="form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    {...register("fullName", { required: true, maxLength: 25 })}
                    style={{
                        border: errors.fullName ? "1px solid #dc3545" :  ""
                    }}
                />
                {errors.fullName && <p className="text-danger">Please enter your full name</p>}
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    {...register("email",
                        {
                            required: true,
                            // eslint-disable-next-line
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}
                    style={{
                        border: errors.email ? "1px solid #dc3545" :  ""
                    }}
                />
                {errors.email && <p className="text-danger">Please enter valid email address</p>}
            </div>

            <div className="form-group">
                <Controller
                    control={control}
                    name="phone"
                    // eslint-disable-next-line
                    rules={{ required: true, pattern: /^[\+\(\s.\-\/\d\)]{9,18}[0-9]$/ }}
                    render={({ field: { ref, ...field } }) => (
                        <PhoneInput
                            {...field}
                            inputProps={{
                                ref,
                                // required: true,
                                // autoFocus: true
                            }}
                            country={"in"}
                            style={{
                                border: errors.email ? "1px solid #dc3545" :  "",
                                borderRadius: errors.email ? "10px" : "0px"
                                
                            }}
                        />
                    )}
                    
                />
                {errors.phone && <p className="text-danger">Please enter mobile number</p>}
            </div>
            

            <div className="form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    {...register("subject", { required: true, maxLength: 100 })}
                    style={{
                        border: errors.subject ? "1px solid #dc3545" :  ""
                    }}
                />
                {errors.subject && <p className="text-danger">Please enter subject of communication</p>}
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    {...register("message", { required: true, maxLength: 100 })}
                    style={{
                        border: errors.message ? "1px solid #dc3545" :  ""
                    }}
                >
                </textarea>
                {errors.message && <p className="text-danger">Please enter your message</p>}
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">Submit Now</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
