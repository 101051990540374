import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: '/images/service/icons/web-development.svg',
        title: 'Web Design & Development',
        description: 'Blending design and technology to create visually stunning websites that inspire and perform.',
        url : '/services/web-design-and-development'
    },
    {
        icon: '/images/service/icons/software-development.svg',
        title: 'Software Development',
        description: 'Innovating your business processes with custom software solutions that meet your unique needs.',
        url : '/services/software-development'
    },
    {
        icon: '/images/service/icons/mobile-app.svg',
        title: 'Mobile App Development',
        description: 'Developing mobile apps that enhance your customers\' lives and simplify their day-to-day activities.',
        url : '/services/mobile-app-development'
    },
    {
        icon: '/images/service/icons/devops.svg',
        title: 'Cloud Services & DevOps',
        description: 'Experience seamless collaboration and uninterrupted uptime with our reliable cloud services.',
        url : '/services/cloud-and-devops'
    },
    {
        icon: '/images/service/icons/e-commerce.svg',
        title: 'E Commerce Solutions',
        description: 'Revolutionize your online store and boost your sales with our e-commerce solutions.',
        url : '/services/ecommerce'
    },
    {
        icon: '/images/service/icons/digital-marketing.svg',
        title: 'Digital <span class="d-none d-xl-inline"><br></span>   Marketing',
        description: 'Accelerate your online growth with our digital marketing expertise.',
        url : '/services/digital-marketing'
    },
    {
        icon: '/images/service/icons/ui-ux-design.svg',
        title: 'UI UX <span class="d-none d-xl-inline"><br></span> Designing',
        description: 'Designing beautiful and intuitive user experiences that drive engagement and loyalty.',
        url : '/services/ui-ux-design'
    },
    {
        icon: '/images/service/icons/software-maintenance.svg',
        title: 'Software <span class="d-none d-xl-inline"><br></span> Maintenance',
        description: 'Ensuring your software runs smoothly, so you can focus on your business.',
        url : '/services/software-maintenance'
    }
]
const ServiceFive = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-12 col-12" key={i}>

                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                <img src={`${val.icon}`} alt="JBS Services" />
                            </div>
                            <div className="content">
                                <h4 className="title"><Link to={`${val.url}`} dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceFive;