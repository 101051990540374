import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import TechStackTag from '../../components/techStack/TechStackTag';
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import ServiceInner from '../service/ServiceInner';
import SlipThree from "../../elements/split/SlipThree";

const ServiceList = [
    {
        title: 'Information Architecture and Wireframing',
        description: 'Build intuitive user experiences with our Information Architecture and Wireframing services. We meticulously organize information hierarchies, creating logical structures that guide users effortlessly. Through detailed wireframes, we visualize the layout and functionality of your digital product, refining the design iteratively to ensure optimal usability. Our collaborative design process ensures your vision is accurately represented, while our expertise in cutting-edge wireframing technologies brings your ideas to life.',
    },
    {
        title: 'Visual Design and Branding',
        description: 'Create memorable and engaging digital experiences with our Visual Design and Branding services. We specialize in crafting visually stunning designs that align with your brand identity. Through our expertise in typography, color palettes, and visual elements, we bring your digital product to life with captivating visuals that leave a lasting impression. Our meticulous attention to detail ensures a cohesive and impactful visual experience across your entire digital presence.',
    },
    {
        title: 'Prototyping and Usability Testing',
        description: 'Refine your designs for optimal usability and user satisfaction. Our expert team creates interactive prototypes that simulate user interfaces, allowing you to visualize and test the user experience before development. Through comprehensive usability testing, we gather valuable feedback to iterate on the design, ensuring a seamless and user-centered digital experience. By embracing an iterative design approach, we continuously refine and improve the user interface based on user feedback and insights.',
    },
    {
        title: 'Responsive Design & Cross-Platform Optimization',
        description: 'Deliver seamless user experiences across devices. Our expert team ensures that your digital product adapts flawlessly to different screen sizes and platforms, providing a consistent and optimized experience. From responsive interfaces to mobile app optimization, we leverage cutting-edge technologies to create engaging and visually compelling designs that captivate users.',
    },
]

const ServiceContent = {
    description : "<p>Create intuitive and engaging user experiences with our specialized UI/UX Design services. Our talented team of UI/UX designers combines their expertise in user research, information architecture, and interaction design to create seamless and delightful experiences across platforms. From conducting in-depth user research and crafting intuitive wireframes to designing visually stunning interfaces and conducting rigorous usability testing, we ensure that every interaction with your digital product is enjoyable and purposeful. With our keen attention to detail and focus on delivering intuitive interfaces, we help you captivate your audience, enhance user engagement, and drive conversions.</p>",
    imagesrc : "/images/service/illustrations/ui-ux-design.svg",
    imagealt : "UI UX Design",
}

const UIUXDesign = () => {
    return(
        <>
            <SEO 
            title="Exceptional UI/UX Design Services | Jubilant Business Solutions" 
            mdescription="Experience exceptional UI/UX design services at Jubilant Business Solutions. Our dedicated team specializes in crafting user-centric interfaces that drive engagement and enhance user satisfaction. From wireframes to interactive prototypes, we create experiences that resonate with your audience."
            keywords="UI/UX design services, User-centric interfaces, Engaging user experiences, Expert UI/UX designers, User satisfaction, Interactive prototypes, User engagement, Jubilant Business Solutions design solutions, Effective user interfaces."
            />
            <Layout>
                

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                {/* <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "UI UX Design"
                                    title = "We are a UI UX Design Company."
                                    description = ""
                                /> */}
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">UI UX Design</span></h3>
                                    </div>
                                    <h1 className="title">We are a UI UX Design Company.</h1>
                                </div>
                            </div>
                        </div>
                        <SlipThree 
                            serviceContent = {ServiceContent}
                        />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "UI UX Design"
                                    title = "Our UI UX Design Services"
                                    description = ""
                                />
                            </div>
                        </div>
                        <ServiceInner 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign = "text-left"
                            serviceLayout = "col-lg-6 col-md-6 col-sm-12 col-12"
                            serviceList = {ServiceList}
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Tech Stack"
                                    title = "Technology Stack"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="category-meta">
                                <TechStackTag />
                            </div>
                        </div>
                    </div>
                </div>
                
            </Layout>

            

                              



        </>
    );
}

export default UIUXDesign;