import React, { useState, useEffect } from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import About from '../elements/about/About';
import ServiceFive from '../elements/service/ServiceFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import BrandFour from "../elements/brand/BrandFour";

const Home = () => {
    const [startTyping, setStartTyping] = useState(false);

    useEffect(() => {
        // Set a timeout to start the typing animation after the page has loaded
        const timer = setTimeout(() => {
            setStartTyping(true);
        }, 3000); // Or any other time that makes sense for your content

        // Cleanup the timeout on component unmount
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <SEO 
            title="Software Development Company - Jubilant Business Solutions" 
            mdescription="Welcome to Jubilant Business Solutions, your leading software development company in India. We specialize in web design, software development, mobile app development, cloud solutions, e-commerce, digital marketing, UI/UX designing, and software maintenance services. Partner with us to turn your ideas into powerful digital solutions."
            keywords="Software development India, Web design services, Mobile app development company, Cloud solutions, DevOps services, E-commerce solutions, Digital marketing agency, UI/UX designing, Software maintenance, Technology partner, Innovative solutions, Trusted company."
            />
            <main className="page-wrapper">
                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-3 height-850 bg_image" data-black-overlay="6" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/banner-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    {/* <span className="subtitle text-white"></span> */}
                                    <h1 className="theme-gradient display-two">We Provide <br /> {" "}
                                        {startTyping && (
                                            <Typed
                                                strings={[
                                                    "Software Development.",
                                                    "Web Development.",
                                                    "App Development.",
                                                    "Digital Marketing.",
                                                ]}
                                                typeSpeed={80}
                                                backSpeed={5}
                                                backDelay={1000}
                                                loop
                                            />
                                        )}
                                    </h1>
                                    <p className="description text-white">We help our clients succeed in solving complex business challenges using our technology solutions.</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/contact">Contact Now <i className="icon"><FiArrowRight /></i></Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">Contact Us <i className="icon"><FiArrowRight /></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* End Slider Area  */}

                
                {/* Start About Area  */}
                <About />                            
                {/* End About Area  */}
                       
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap bg-theme-gradient home-service-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    // subtitle = "What we can do for you"
                                    subtitle = "Transform your digital world with our services."
                                    title = "Our Services"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceFive 
                            serviceStyle = "service__style--1 radius mt--25"
                            textAlign = "text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Work Process */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "How We Work For Our Clients"
                                        title = "Our Working Process."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--50">
                                <TimelineTwo classVar="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Work Process */}    

                <Separator /> 
                {/* Start Mission Area   */}
                <Mission />                    
                {/* Start Mission Area  */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gap bg-theme-gradient">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Awesome Client"
                                    title = "Our Client."
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandFour brandStyle="brand-style-2 variation-2" />
                            </div>
                        </div>
                    </div>
                </div>
                    {/* End Brand Area  */}
                         
                {/* Start Testimonial Area  */}
                {/* <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "Our Awesome Client."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div> */}
                {/* End Testimonial Area  */}


                {/* <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Our Latest News."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default Home;
