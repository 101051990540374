import React from 'react';
import {Link} from "react-router-dom";

const About = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about.png" alt="Jubilant Business Solutions" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">About Us <br /> {" "}
                        
                                </h2>
                                <p>Behind every Machine is a human and behind every concept is a brain that thinks differently.
                                    Unique brains working on a common mission and having a similar vision, merging multiple concepts to bring out the best is what gave the birth to Jubilant Business Solutions.
                                </p>
                                <p>
                                    Established in the IT Hub of Telangana – Hyderabad by qualified Engineers of various verticals to provide business solutions to Existing and Startup Organizations.
                                    Rendering services like Payroll Management, Web Development, Software Maintenance, upgradation, providing guidance and concept to increase Productivity, Marketing and assisting in cost reductions and increase in profitability.
                                    Working on delivering the best solutions with strong business ethics and clientele satisfaction is the prime motto.
                                </p>
                                <div className="read-more-btn mt--40">
                                    <Link className="btn-default" to="/about-us"><span>More About Us</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default About
