import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Why choose Jubilant Business Solution?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        We are a team of dedicated engineers who understand business requirements, analyse and develop a solution that meet up to the requirements of our client and help them overcome the challenges into day-to-day operations. 
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                   What Services do you offer?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>We provide full-scale software development services such as - Web Design & Web Development, Mobile App Development, Custom Software Development, E-commerce Solutions, Digital Marketing. We provide custom made solutions for - CMS, CRM, ERP, Inventory Managment, Payroll Managment.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    What technologies do we use?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Our software development services are inclusive of but not limited to range of technologies, diversified frameworks and programming languages. We suggest and assist businesses by analyzing their business requirement, budget, future demands and available technology stack.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Do you provide maintenance of software?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Yes, We provide support and maintenance after deployment of software. Our maintenance service includes - feature enhancement, optimization, bug fixing and preventive manintenance.</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

