import axios from "axios";

let url;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  url = 'http://localhost/phpmailer';
} else {
  url = 'https://jubilantbusinesssolutions.com/api';
}

// console.log(url);

const instance = axios.create({
    baseURL: url,
});

export default instance;
