import React from 'react';
import {flatDeep, slugify, containsObject} from '../../utils';
import {Link} from "react-router-dom";


const TechStackTag = () => {

    const tags = ["PHP", ".Net", "Node", "Python", "Ruby on Rails", "WordPress", "WooCommerce", "Angular", "React", "Vue", "SQL Server", "MySQL", "PostgreSQL", "MongoDB", "Redis", "iOS", "Android", "React Native", "Flutter", "Amazon Web Services", "Azure", "Google Cloud", "Jenkins", "Docker", "Kubernetes", "Jira", "Postman", "Selenium"];
   
    let singleTagArray = flatDeep(tags);
    let tagsData = [];
    singleTagArray.forEach(tag => {
        const obj = {
            title: tag.trim(),
            slug: slugify(tag)
        }
        const objIndex = containsObject(obj, tagsData);
        if(objIndex !== -1){
            tagsData[objIndex] = {
                title: tag.trim(),
                slug: slugify(tag)
            }
        } else {
            tagsData.push(obj);
        }
    })
    return (
        <ul className="tagcloud">
            {tagsData.map((tag) => {
                return(
                    // <Link key={tag.slug} to={process.env.PUBLIC_URL + `/technology/${tag.slug}`}>{tag.title}</Link>
                    <Link key={tag.slug} to={process.env.PUBLIC_URL}>{tag.title}</Link>
                )
            })}
        </ul>
    )
}

export default TechStackTag
